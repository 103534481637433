import {ChangeDetectionStrategy, Component, computed, inject, input, Signal} from '@angular/core';
import {BreadcrumbComponent, BreadcrumbItem} from "../../breadcrumb/breadcrumb.component";
import {Category, CategoryService} from "../../category.service";
import {BreadcrumbBase} from "../../breadcrumb/breadcrumb-base";

@Component({
  selector: 'app-category-breadcrumb',
  imports: [
    BreadcrumbComponent
  ],
  templateUrl: './category-breadcrumb.component.html',
  styleUrl: './category-breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryBreadcrumbComponent extends BreadcrumbBase<Category> {
  categoryService = inject(CategoryService);
  category = input.required<Category>();

  get items(): Signal<Category[]> {
    return computed(() => {
      return this.categoryService.categories() ?? [];
    });
  }

  mapItemToBreadcrumbItem(item: Category): BreadcrumbItem {
    return {
      id: item.id,
      name: item.name,
      url: item.seoUrl ? '/' + item.seoUrl.seoPathInfo : '',
    };
  }

  get treeOfCurrentItem(): Signal<string[]> {
    return computed(() => {
      const category = this.category();
      return category?.tree ?? [];
    });
  }
}
