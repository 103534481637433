<div
  class="grid gap-y-4 px-6 md:px-0 pb-4 grid-cols-[max-content_1fr_max-content_1fr_max-content]"
>
  @if (showHeightPotProperties()) {
    <app-property-item [label]="'Höhe'">
      <app-property
        [product]="product()"
        [type]="'height'"
        [iconEnabled]="false"
      ></app-property>
    </app-property-item>
    <div></div>
    <app-property-item [label]="'Preis'">
      <div class="flex justify-center md:justify-start items-center">
        <div
        >
          {{ product().calculatedPrice.totalPrice | currency }}
        </div>
      </div>
    </app-property-item>
    <div></div>
    <app-property-item [label]="'Topfgrösse'">
      <app-property
        [product]="product()"
        [type]="'potsize'"
        [iconEnabled]="false"
      ></app-property>
    </app-property-item>
  }

  @if (showPlantProperties()) {
    <app-property-item [label]="'Licht'">
      <app-property-range
        [product]="product()"
        [type]="'light'"
      ></app-property-range>
    </app-property-item>
    <div></div>
    <app-property-item [label]="'Pflege'">
      <app-property-range
        [product]="product()"
        [type]="'skill'"
      ></app-property-range>
    </app-property-item>
    <div></div>
    <app-property-item [label]="'Wasserbedarf'">
      <app-property-range
        [product]="product()"
        [type]="'water'"
      ></app-property-range>
    </app-property-item>
  }

  @if (showAccessoryProperties()) {
    <app-property-item [label]="'Preis'">
      <div class="flex justify-center md:justify-start items-center">
        <div
          [ngClass]="{
            'text-red-700':
              product().calculatedPrice.listPrice && product().markAsTopseller,
          }"
        >
          {{ product().calculatedPrice.totalPrice | currency }}
        </div>
        @if (product().calculatedPrice.listPrice && product().markAsTopseller) {
          <div class="line-through text-sm ml-2 font-light">
            {{ product().calculatedPrice.listPrice?.price | currency }}
          </div>
        }
      </div>
    </app-property-item>
  }
</div>
