import {ChangeDetectionStrategy, Component, computed, input, output} from '@angular/core';
import {NgClass} from "@angular/common";
import {Hierarchy} from "../collapsable-hierarchy/collapsable-hierarchy.component";
import {ButtonTextComponent} from "../button-text/button-text.component";
import {RouterLink} from "@angular/router";
import {Category} from "../../category.service";

@Component({
  selector: 'app-show-more-hierarchies',
  imports: [
    NgClass,
    ButtonTextComponent,
    RouterLink
  ],
  templateUrl: './show-more-hierarchies.component.html',
  styleUrl: './show-more-hierarchies.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowMoreHierarchiesComponent<T extends Category> {
  hierarchies = input.required<Hierarchy<T>[]>();
  selectedHierarchy = input.required<Hierarchy<T> | null>();
  showMoreChanged = output<Hierarchy<T> | null>();

  selectedIndex = computed(() => {
    const hierarchies = this.hierarchies();
    const selectedHierarchy = this.selectedHierarchy();

    if (!hierarchies || !selectedHierarchy) {
      return null;
    }

    const index = hierarchies.findIndex(hierarchy => hierarchy.item.id === selectedHierarchy.item.id);

    return index === -1 ? null : index;
  });
}
