import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {NgClass} from "@angular/common";

interface SeoParagraph {
  text: string;
  isHeader: boolean;
}

@Component({
  selector: 'app-seo-text',
  imports: [
    NgClass,
  ],
  templateUrl: './seo-text.component.html',
  styleUrl: './seo-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeoTextComponent {
  seoText = input.required<string>();

  paragraphs = computed<SeoParagraph[]>(() => this.parseParagraphs(this.seoText()));

  parseParagraphs(text: string): SeoParagraph[] {
    if (!text) {
      return [];
    }

    const lines = text.split('//');

    const paragraphs: SeoParagraph[] = [];

    for (const line of lines) {
      if (line.startsWith('b ')) {
        if (paragraphs.length > 0) {
          paragraphs[paragraphs.length - 1].isHeader = true;
        }
        paragraphs.push({text: line.slice(2), isHeader: false});
      } else {
        paragraphs.push({text: line, isHeader: false});
      }
    }

    return paragraphs;
  }
}
