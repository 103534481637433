<div class="flex items-center" [style.z-index]="stickyOption().isSticky ? stickyOption().zIndex: '0'" [ngClass]="{'sticky-header' : stickyOption().isSticky && open(),'border-b border-gray-100' : open()}">
  <div class="flex-grow min-w-0">
    <ng-content select="app-collapsable-header"></ng-content>
  </div>
  <button class="px-6" (click)="toggle()">
    <div  [ngClass]="{'rotate-90': open()}" class="transition-all">
      <fa-icon [icon]="faCaretRight"></fa-icon>
    </div>
  </button>
</div>
<div [style.max-height]="open() ? '100%' : 0" class="overflow-hidden" [ngClass]="{'open' : open()}">
  <ng-content select="app-collapsable-body"></ng-content>
</div>
