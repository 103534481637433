import {ChangeDetectionStrategy, Component, effect, input, signal} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { NgClass } from '@angular/common';
import {StickyOption} from "../collapsable-hierarchy/collapsable-hierarchy.component";

@Component({
  selector: 'app-collapsable',
  imports: [
      FaIconComponent,
      NgClass,
  ],
  templateUrl: './collapsable.component.html',
  styleUrl: './collapsable.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.open]': 'open()',
  },
})
export class CollapsableComponent {
  initialOpen = input(false);
  stickyOption = input<StickyOption>({
    isSticky: false,
    zIndex: 0
  });

  open = signal(false)

  constructor(
  ) {
    effect(() => {
      const initialOpen = this.initialOpen();

      this.open.set(initialOpen);
    });
  }

  toggle() {
    this.open.update(s => !s);
  }

  protected readonly faCaretRight = faCaretRight;
}
