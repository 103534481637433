<div class="min-h-16 flex items-center">
  @if (selectedHierarchy()) {
    <app-button-text [text]="'Alle Kategorien anzeigen'" (click)="showMoreChanged.emit(null)"></app-button-text>
  }
</div>
<div class="columns" [ngClass]="'view-'+ (selectedIndex() ?? 'default')">
  @for (hierarchy of hierarchies(); track hierarchy.item.id) {
    <div class="flex flex-col overflow-hidden">
      <a class="link pb-2 font-semibold text-gray-700 hover:underline" [href]="hierarchy.item.url">{{hierarchy.item.name}}</a>
      <div class="items" [ngClass]="{'detail' : hierarchy.showMoreOption && hierarchy.showMoreOption.selected, 'hidden-items': !!selectedHierarchy() && hierarchy.showMoreOption && !hierarchy.showMoreOption.selected}">
        @for (subCategory of hierarchy.children; track subCategory.item.id; let index = $index) {
          @if (hierarchy.showMoreOption && hierarchy.showMoreOption.count === index) {
            <app-button-text class='pt-2' [text]="'Alle Einträge anzeigen'" (click)="showMoreChanged.emit(hierarchy)"></app-button-text>
          } @else {
            <div class="item" [ngClass]="{'show-more-hidden': hierarchy.showMoreOption && hierarchy.showMoreOption.count <= index }">
              <a class="link flex items-center flex-grow min-w-0 space-x-6 py-2 text-gray-700 hover:underline " [routerLink]="subCategory.item.url">
                {{
                  subCategory.item.name
                }}
              </a>
            </div>
          }
        }
      </div>
    </div>
  }
</div>
