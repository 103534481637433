<div class="bg-white flex flex-col mr-4 flex-grow min-h-0">
  <div class="px-6 py-3 flex items-center w-full justify-between">
    <div>
      <app-static-asset
        [alt]="'zimmerpflanzen.de'"
        [extension]="'png'"
        [source]="'assets/static/logo_combined_horizontal_h60'"
        class="h-6"
      ></app-static-asset>
    </div>
    <button aria-label="Menu schliessen" class="text-2xl text-primary" (click)="close()">
      <fa-icon [icon]="closeIcon"></fa-icon>
    </button>
  </div>

  <div class="flex-grow flex-col min-h-0 overflow-y-auto relative">
    <app-section-items>
      <app-collapsable [initialOpen]="true" [stickyOption]="shopStickyOption" class="text-primary">
        <app-collapsable-header class="bg-white">
          <app-section-header>Shop</app-section-header>
        </app-collapsable-header>
        <app-collapsable-body>
          <app-section-item (click)="close()"
          ><a class="link" [routerLink]="'/'">Startseite</a></app-section-item
          >
          <app-section-item (click)="close()"
          ><a
            class="link"
            [routerLink]="'/'"
            [fragment]="'sonderangebote'"
            title="Sonderangebote Zimmerpflanzen"
          >Sonderangebote</a
          ></app-section-item
          >
          @for (
              hierarchy of hierarchicalCategoriesWithTemplates();
            track hierarchy.item.id
            ) {
            @if (hierarchy.children.length > 0) {
              <app-collapsable-hierarchy [stickyOption]="shopStickyOption" class="text-gray-500" [hierarchy]="hierarchy" [defaultItemTemplate]="itemTemplate()"></app-collapsable-hierarchy>
            } @else {
              <app-section-item (click)="close()"
              ><a class="link" [routerLink]="hierarchy.item.url">{{
                  hierarchy.item.name
                }}</a></app-section-item
              >
            }
          }
        </app-collapsable-body>
      </app-collapsable>
    </app-section-items>

    <app-section-items>
      <app-collapsable [stickyOption]="informationStickyOption" class="text-primary">
        <app-collapsable-header>
          <app-section-header>Informationen</app-section-header>
        </app-collapsable-header>
        <app-collapsable-body>
          <app-section-item
            ><a
              class="link"
              [routerLink]="['./informationen/ueber-uns']"
              (click)="close()"
              >Über uns</a
            ></app-section-item
          >
          <app-section-item
            ><a class="link" href="/blog" (click)="close()"
              >Unser Blog</a
            ></app-section-item
          >
          <app-section-item
            ><a
              class="link"
              [routerLink]="['./informationen/versand']"
              (click)="close()"
              >Versandinformationen</a
            ></app-section-item
          >
          <app-section-item
            ><a
              class="link"
              [routerLink]="['./informationen/zahlungsmethoden']"
              (click)="close()"
              >Zahlungsmethoden</a
            ></app-section-item
          >
          <app-section-item
            ><a
              class="link"
              [routerLink]="['./informationen/schadensmeldung']"
              (click)="close()"
              >Schadensmeldung</a
            ></app-section-item
          >
        </app-collapsable-body>
      </app-collapsable>
    </app-section-items>

    <app-section-items>
      <app-collapsable [stickyOption]="accountStickyOption" class="text-primary">
        <app-collapsable-header>
      <app-section-header>Konto</app-section-header>
        </app-collapsable-header>
        <app-collapsable-body>
          @if (customerState.isLoggedIn()) {
            <app-section-item
            ><a
              class="link"
              (click)="close()"
              [routerLink]="['./account/overview']"
            >Bestellübersicht</a
            ></app-section-item
            >
            <app-section-item
            ><a
              class="link"
              (click)="close()"
              [routerLink]="['./account/wishlist']"
            >Wunschliste</a
            ></app-section-item
            >
          } @else {
            <app-section-item
            ><a class="link" (click)="close()" [routerLink]="['./account/login']"
            >Konto anmelden</a
            ></app-section-item
            >
            <app-section-item
            ><a
              class="link"
              (click)="close()"
              [routerLink]="['./account/register']"
            >Konto erstellen</a
            ></app-section-item
            >
          }
        </app-collapsable-body>
      </app-collapsable>
    </app-section-items>

    <app-section-items>
      <app-collapsable [stickyOption]="generalStickyOption" class="text-primary">
        <app-collapsable-header>
          <app-section-header>Allgemein</app-section-header>
        </app-collapsable-header>
        <app-collapsable-body>
          <app-section-item
          ><a
            class="link"
            (click)="close()"
            [routerLink]="['./informationen/agb']"
          >AGB</a
          ></app-section-item
          >

          <app-section-item
          ><a
            class="link"
            (click)="close()"
            [routerLink]="['./informationen/wiederrufsrecht']"
          >Wiederrufsrecht</a
          ></app-section-item
          >
          <app-section-item
          ><a
            class="link"
            (click)="close()"
            [routerLink]="['./informationen/datenschutz']"
          >Datenschutz</a
          ></app-section-item
          >
          <app-section-item
          ><a
            class="link"
            (click)="close()"
            [routerLink]="['./informationen/impressum']"
          >Impressum</a
          ></app-section-item
          >
        </app-collapsable-body>
      </app-collapsable>
    </app-section-items>
  </div>
</div>
<ng-template #defaultItemTemplate let-item="item">
  <app-section-item (click)="close()"
  ><a class="link" [routerLink]="item.url">{{
      item.name
    }}</a></app-section-item
  >
</ng-template>
<ng-template #plantCategoryItemTemplate let-item="item">
  <div class="flex items-center flex-grow min-w-0 border-l-4 border-primary overflow-hidden">
    <app-section-item (click)="close()"
    ><a class="link flex items-center flex-grow min-w-0 space-x-6" [routerLink]="item.url">
      <div class="flex flex-col flex-grow min-w-0">
        <div>{{
            item.name
          }}</div>
        @if (item.navDescription) {
          <div class="text-sm text-gray-500">
            {{item.navDescription}}
          </div>
        }
      </div>
      @if (item.media) {
        <img class="object-cover h-16" [src]="item.media" [alt]="item.name"/>
      }
    </a></app-section-item
    >
  </div>
</ng-template>
