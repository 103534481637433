import {
  ChangeDetectionStrategy,
  Component, computed,
  inject, OnInit,
  signal, TemplateRef, viewChild,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { StaticAssetComponent } from '../components/static-asset/static-asset.component';
import { SectionHeaderComponent } from '../components/section-header/section-header.component';
import { SectionItemComponent } from '../components/section-item/section-item.component';
import { SectionItemsComponent } from '../components/section-items/section-items.component';
import { RouterLink } from '@angular/router';
import {Category, CategoryService} from '../category.service';
import { CustomerStateService } from '../account/customer-state.service';
import {CollapsableComponent} from "../components/collapsable/collapsable.component";
import {CollapsableBodyComponent} from "../components/collapsable-body/collapsable-body.component";
import {CollapsableHeaderComponent} from "../components/collapsable-header/collapsable-header.component";
import {
  CollapsableHierarchyComponent, Hierarchy, StickyOption,
} from "../components/collapsable-hierarchy/collapsable-hierarchy.component";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";

@Component({
  selector: 'app-sidebar',
  imports: [
    FaIconComponent,
    StaticAssetComponent,
    SectionHeaderComponent,
    SectionItemComponent,
    SectionItemsComponent,
    RouterLink,
    CollapsableComponent,
    CollapsableHeaderComponent,
    CollapsableBodyComponent,
    CollapsableHierarchyComponent,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.open]': 'opened()'
  },
})
export class SidebarComponent implements OnInit {
  itemTemplate = viewChild.required<TemplateRef<{ item: Category }>>('defaultItemTemplate');
  private readonly level3ItemTemplate = viewChild.required<TemplateRef<{item: Category }>>('plantCategoryItemTemplate');
  templateMap = new Map<number, TemplateRef<{ item: Category }>>();
  opened = signal(false);
  closeIcon = faTimes;
  private readonly category = inject(CategoryService);
  customerState = inject(CustomerStateService);

  hierarchicalCategoriesWithTemplates = computed(() => {
    return this.category.hierarchicalCategories().map((h) => this.mapTemplateToHierarchy(h));
  });

  shopStickyOption: StickyOption = {
    isSticky: true,
    zIndex: 100,
  }

  informationStickyOption: StickyOption = {
    isSticky: true,
    zIndex: 200,
  }

  accountStickyOption: StickyOption = {
    isSticky: true,
    zIndex: 300,
  }

  generalStickyOption: StickyOption = {
    isSticky: true,
    zIndex: 400,
  }

  private mapTemplateToHierarchy(hierarchy: Hierarchy<Category>): Hierarchy<Category> {
    return ({
      item: hierarchy.item,
      itemTemplate: this.resolveTemplate(hierarchy.item.level),
      children: hierarchy.children.map((c) => this.mapTemplateToHierarchy(c)),
      level: hierarchy.level,
      showMoreOption: hierarchy.showMoreOption,
    });
  }

  ngOnInit(): void {
    this.templateMap.set(3, this.level3ItemTemplate());
  }

  private resolveTemplate(level: number): TemplateRef<{ item: Category }> {
    return this.templateMap.get(level) ?? this.itemTemplate();
  }

  open() {
    this.opened.set(true);
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.opened.set(false);
    document.body.style.overflow = '';
  }
}
