import {ChangeDetectionStrategy, Component, input} from '@angular/core';

@Component({
  selector: 'app-button-text',
  imports: [],
  templateUrl: './button-text.component.html',
  styleUrl: './button-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonTextComponent {
  text = input.required<string>();
}
