import {InjectionToken} from "@angular/core";
import {Schemas} from "../api-types/storeApiTypes";
import {Metadata} from "@stripe/stripe-js";

export interface ServerInfo {
  urlFound: boolean;
  isProduct: boolean;
  seoUrl: Schemas['SeoUrl'] | undefined;
  type: 'default' | 'server';
  meta: Metadata | undefined;
}

export const ServerInfo = new InjectionToken<ServerInfo>('ZIMMERPFLANZEN_SERVER_INFO');
