import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {RouterLink} from "@angular/router";

export interface BreadcrumbItem
{ id: string, name: string, url: string };


@Component({
  selector: 'app-breadcrumb',
  imports: [
    RouterLink
  ],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent {
  items = input.required<BreadcrumbItem[]>();
}
