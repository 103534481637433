<app-section-items>
  <app-section-header>Versand</app-section-header>
  @for (delivery of cartState().deliveries; track $index) {
    <app-section-item>
      <div class="flex justify-between">
        <span>{{ delivery.shippingMethod?.name }}</span>
        @if (delivery.shippingCosts; as costs) {
          @if (costs.totalPrice > 0) {
            <span>{{ delivery.shippingCosts.totalPrice | currency }}</span>
          } @else {
            <span class="">Kostenlos</span>
          }
        }
      </div>
    </app-section-item>
    @if (delivery.deliveryDate) {
      <app-section-item>
        <div class="flex justify-between space-x-4">
          <span>Lieferzeitraum</span>
          <span>
            {{ delivery.deliveryDate.earliest | date }} -
            {{ delivery.deliveryDate.latest | date }}</span
          >
        </div>
      </app-section-item>
    }

    @if (delivery.shippingCosts; as costs) {
      @if (costs.totalPrice > 0) {
        <div class="bg-yellow-100 py-2 text-yellow-800 border-t">
          <app-container>
            <div class="flex items-center space-x-2">
              <!--                <fa-icon [icon]="faInfoCircle"></fa-icon>-->
              <div class="">Kostenlose Lieferung ab {{ 100 | currency }}</div>
            </div>
          </app-container>
        </div>
      }
    }
  }
</app-section-items>
