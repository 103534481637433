<app-container [padding]="'none md:px-6'">
  <div class="header">
    <button
      class="flex-shrink-0 pl-6 md:hidden"
      aria-label="Navigationmenu öffnen"
      (click)="sidebar().open()"
    >
      <fa-icon [icon]="hamburgerIcon" class="text-lg text-primary"></fa-icon>
    </button>
    <a
      class="md:flex flex-grow min-w-0 py-3 items-center space-x-3"
      title="Zimmerpflanzen.de"
      [routerLink]="'/'"
    >
      <app-static-asset
        [alt]="'zimmerpflanzen.de'"
        [extension]="'png'"
        [loading]="'eager'"
        [source]="'assets/static/logo_combined_horizontal_h60'"
        class="h-6"
      ></app-static-asset>
    </a>
    <div class="flex-shrink-0 flex items-center">
      @for (item of items; track $index) {
        @if (item.visible()) {
          <div class="flex-shrink-0 z-10 {{ item.class }}">
            <app-navbar-item
              [icon]="item.icon"
              [link]="item.link"
              [text]="item.text"
              [badge]="item.badge()"
              [active]="item.active()"
            ></app-navbar-item>
          </div>
        }
      }
    </div>
  </div>
</app-container>
<div class="hover-menu">
  <app-container class="overflow-hidden pt-3 pb-16">
    <app-show-more-hierarchies
      [selectedHierarchy]="category.selectedShowMoreHierarchicalCategory()"
      [hierarchies]="category.showMoreHierarchicalCategories()"
      (showMoreChanged)="category.updateShowMoreHierarchicalCategory($event)">
    </app-show-more-hierarchies>
  </app-container>
</div>
