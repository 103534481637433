import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  signal,
} from '@angular/core';
import { Schemas } from '../../../api-types/storeApiTypes';
import {DatePipe, NgClass, PercentPipe} from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { InputComponent } from '../../components/input/input.component';
import { TextareaComponent } from '../../components/textarea/textarea.component';
import { StarsComponent } from '../../components/stars/stars.component';
import { ProductService } from '../../product.service';
import { CustomerStateService } from '../../account/customer-state.service';
import { InputLabelComponent } from '../../components/input-label/input-label.component';
import { ensureValidity } from '../../form';

@Component({
  selector: 'app-product-review',
  imports: [
    PercentPipe,
    NgClass,
    InputComponent,
    TextareaComponent,
    StarsComponent,
    InputLabelComponent,
    DatePipe,
  ],
  templateUrl: './product-review.component.html',
  styleUrl: './product-review.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductReviewComponent {
  product = input.required<Schemas['Product']>();
  productService = inject(ProductService);
  customerState = inject(CustomerStateService);

  fb = inject(FormBuilder);

  points = [5, 4, 3, 2, 1];
  form = this.fb.group({
    title: this.fb.nonNullable.control<string>('', [
      Validators.minLength(5),
      Validators.required,
    ]),
    content: this.fb.nonNullable.control<string>('', [
      Validators.minLength(40),
      Validators.required,
    ]),
    points: this.fb.nonNullable.control<number>(0, [
      Validators.min(1),
      Validators.max(5),
      Validators.required,
    ]),
  });

  reviewMode = signal<'none' | 'open' | 'sent'>('none');

  activeReviews = computed(() => {
    const product = this.product();
    const reviews = product.productReviews || [];
    return reviews.filter((r) => r.status === true);
  });

  averageReviewPoints = computed(() => {
    const reviews = this.activeReviews();
    const totalPoints = reviews.reduce((sum, review) => sum + review.points, 0);
    return Math.ceil(totalPoints / reviews.length);
  });

  totalReviews = computed(() => {
    const reviews = this.activeReviews();
    return reviews.length;
  });

  pointsSummary = computed(() => {
    const reviews = this.activeReviews();
    const totalReviews = reviews.length;
    return this.points.map((p) => {
      const pointReviews = reviews.filter((r) => r.points === p);
      return {
        point: p,
        percentage: this.saveDivision(pointReviews.length, totalReviews),
      };
    });
  });

  async createReview() {
    if (this.reviewMode() === 'open') {
      if (!ensureValidity(this.form, { scroll: false })) {
        return;
      }

      const value = this.form.getRawValue();
      await this.productService.review(this.product().id, {
        content: value.content,
        points: value.points,
        title: value.title,
      });
    } else if (this.reviewMode() === 'none') {
      const signedUp = await this.customerState.ensureSignedUp();
      if (!signedUp) {
        return;
      }
    }

    this.reviewMode.update((mode) => {
      if (mode === 'none') {
        return 'open';
      } else if (mode === 'open') {
        return 'sent';
      } else {
        return 'none';
      }
    });
  }

  setPoints(points: number) {
    console.log(points);
    this.form.controls.points.patchValue(points);
  }

  private saveDivision(first: number, second: number) {
    if (second === 0) {
      return 0;
    } else {
      return first / second;
    }
  }
}
