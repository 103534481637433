@if (category.current(); as category) {
  @if (category.description) {
    <div
      class="flex flex-col mb-6  pb-3"
    >
      <h1 class="text-3xl mb-3">{{ category.name }}</h1>
      <p>{{ category.description }}</p>
    </div>
  }
}
