import {computed, Signal} from "@angular/core";
import {BreadcrumbItem} from "./breadcrumb.component";

export abstract class BreadcrumbBase<T extends { id: string; }> {
  abstract get items(): Signal<T[]>;
  abstract get treeOfCurrentItem(): Signal<string[]>;

  abstract mapItemToBreadcrumbItem(item: T): BreadcrumbItem;

  breadcrumbs = computed<BreadcrumbItem[]>(() => {
    const tree = this.treeOfCurrentItem();
    const items = this.items();

    if (!tree || !items) {
      return [];
    }

    return tree
      .map((itemId) => {
        const item = items.find(
          (category) => category.id === itemId,
        );
        if (!item) {
          return undefined;
        }

        return this.mapItemToBreadcrumbItem(item);
      }).filter(isDefined);

  });
}

export const isDefined = <T>(value: T | undefined): value is T => value !== undefined;
