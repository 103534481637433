import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input, Signal,
} from '@angular/core';
import {components, Schemas} from '../../../api-types/storeApiTypes';
import {BreadcrumbComponent, BreadcrumbItem} from "../../breadcrumb/breadcrumb.component";
import {BreadcrumbBase} from "../../breadcrumb/breadcrumb-base";

@Component({
  selector: 'app-product-breadcrumb',
  imports: [BreadcrumbComponent],
  templateUrl: './product-breadcrumb.component.html',
  styleUrl: './product-breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductBreadcrumbComponent extends BreadcrumbBase<Schemas['Category']> {
  product = input.required<Schemas['Product']>();

  get items(): Signal<Schemas["Category"][]> {
    return computed(() => {
      const categories = this.product()?.categories ?? [];

      const mainCategories: components['schemas']['Category'][] = [];

      for (const category of categories.sort((a, b) => a.breadcrumb.length - b.breadcrumb.length)) {
        const hasParentAndNoSibling = mainCategories.some(v => v.id === category.parentId && v.parentId !== category.parentId);
        if (mainCategories.length === 0 ||
          hasParentAndNoSibling) {
          mainCategories.push(category);
        }
      }

      return mainCategories;
    });
  }

  mapItemToBreadcrumbItem(item: Schemas["Category"]): BreadcrumbItem {
    return {
      id: item.id,
      name: item.translated.name!,
      url: item.seoUrls ? '/' + item.seoUrls[0]?.seoPathInfo : '',
    };
  }

  get treeOfCurrentItem(): Signal<string[]> {
    return computed(() => {
      const product = this.product();

      if (!product || !product.categoryTree) {
        return [];
      }

      return product.categoryTree
          .slice(1);
    });
  }
}
