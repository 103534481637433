import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { CollapsableOptionGroupComponent } from '../collapsable-option-group/collapsable-option-group.component';
import { FilterOptionsComponent } from '../filter-options/filter-options.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-collapsable-option',
    imports: [NgClass, RouterLink],
    templateUrl: './collapsable-option.component.html',
    styleUrl: './collapsable-option.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsableOptionComponent {
  label = input.required<string>();
  id = input.required<string>();
  index = input<number | undefined>();
  link = input<string>();
  selected = output<void>();
  group = inject(CollapsableOptionGroupComponent, { optional: true });
  filter = inject(FilterOptionsComponent, { optional: true });

  active = computed(() => {
    if (this.group) {
      return this.group.selected().indexOf(this.id()) !== -1;
    }

    if (this.filter) {
      return this.filter.selected().indexOf(this.id()) !== -1;
    }

    return false;
  });

  emit() {
    if (this.active()) {
      return;
    }

    this.selected.emit();
  }
}
