<div class="bg-white my-8 relative">
  <div class="lg:grid lg:grid-cols-12 lg:gap-x-8">
    <div class="lg:col-span-4">
      <h2 class="text-2xl mb-8 underline decoration-primary">Bewertungen</h2>

      @if (totalReviews() > 0) {
        <div class="mt-3 flex items-center">
          <div>
            <app-stars [selectedPoint]="averageReviewPoints()"></app-stars>
            <p class="sr-only">4 out of 5 stars</p>
          </div>
          <p class="ml-2 text-sm text-gray-900">
            Basierend auf {{ totalReviews() }} Bewertungen
          </p>
        </div>
      }

      <div class="mt-6">
        <h3 class="sr-only">Bewertungsdaten</h3>

        <dl class="space-y-3">
          @for (point of pointsSummary(); track $index) {
            <div class="flex items-center text-sm">
              <dt class="flex flex-1 items-center">
                <p class="w-3 font-medium text-gray-900">
                  {{ point.point
                  }}<span class="sr-only"> Sterne Bewertungen</span>
                </p>
                <div aria-hidden="true" class="ml-1 flex flex-1 items-center">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-yellow-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <div class="relative ml-3 flex-1">
                    <div
                      class="h-3 rounded-full border border-gray-200 bg-gray-100"
                    ></div>
                    <div
                      [style.width.%]="point.percentage * 100"
                      class="absolute inset-y-0 rounded-full border border-yellow-400 bg-yellow-400"
                    ></div>
                  </div>
                </div>
              </dt>
              <dd
                class="ml-3 w-10 text-right text-sm tabular-nums text-gray-900"
              >
                {{ point.percentage | percent }}
              </dd>
            </div>
          }
        </dl>
      </div>
    </div>

    <div class="mt-16 lg:col-span-7 lg:col-start-6 lg:mt-0">
      <h3 class="sr-only">Aktuelle Bewertungen</h3>

      <div class="flow-root">
        <div
          class="divide-y overflow-hidden divide-gray-200 border-gray-200 md:border-t-0"
          [ngClass]="{
            'border-y': activeReviews().length > 0,
            'h-0': reviewMode() !== 'none',
            'h-auto': reviewMode() === 'none',
          }"
        >
          @for (review of activeReviews(); track review.id) {
            <div class="py-6">
              <div class="flex items-center">
                <div class="">
                  <h4 class="flex items-center space-x-1 text-sm font-bold text-gray-900">
                    @if (review.externalUser) {
                      <span>{{ review.externalUser }},</span>
                    }
                    <span>{{ review.updatedAt | date: 'dd. MMMM yyyy' }}</span>
                  </h4>
                  <div class="mt-1 flex items-center">
                    <app-stars [selectedPoint]="review.points"></app-stars>
                  </div>
                  <p class="sr-only">{{ review.points }} von 5 Sternen</p>
                </div>
              </div>

              <div class="mt-4 space-y-6 text-base italic text-gray-600">
                <p>
                  {{ review.title }}
                </p>
              </div>
            </div>
          }
        </div>

        <div class="mt-10">
          <h3 class="text-lg font-medium text-gray-900">
            Teile deine Erfahrung
          </h3>
          <p class="my-1 text-sm text-gray-600">
            Wenn Sie dieses Produkt gekauft haben, teilen Sie Ihre Gedanken mit
            anderen Kunden
          </p>

          <div
            [ngClass]="{
              'h-0': reviewMode() !== 'open',
              'h-auto': reviewMode() === 'open',
            }"
            class="overflow-hidden flex flex-col transition-all space-y-3"
          >
            <app-input
              [control]="form.controls.title"
              [label]="'Titel'"
            ></app-input>

            <app-textarea
              [control]="form.controls.content"
              [label]="'Bewertung'"
            ></app-textarea>

            <app-input-label
              [label]="'Sterne'"
              [control]="form.controls.points"
            ></app-input-label>
            <div class="mt-2">
              <app-stars
                [size]="'w-10 h-10 cursor-pointer'"
                id="form-field-Sterne"
                [selectedPoint]="form.controls.points.value"
                (pointSelected)="setPoints($event)"
              ></app-stars>
            </div>
          </div>

          <div
            [ngClass]="{
              'h-0': reviewMode() !== 'sent',
              'h-auto': reviewMode() === 'sent',
            }"
            class="overflow-hidden flex flex-col transition-all space-y-3"
          >
            <div
              class="rounded-md px-3 py-2 bg-primary/10 border text-primary border-primary"
            >
              Vielen Dank für deine Bewertung.
            </div>
          </div>

          @if (reviewMode() !== "sent") {
            <button
              (click)="createReview()"
              class="mt-6 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
            >
              Bewertung erfassen
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</div>
