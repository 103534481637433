<app-header [header]="'Informationen'"></app-header>

<div class="flex flex-col flex-grow min-h-0 mb-4 gap-y-8">
  <app-static-asset
    [alt]="'zimmerpflanzen.de'"
    [extension]="'png'"
    [source]="'assets/static/logo_combined_vertical'"
    class="h-32 mt-6"
  ></app-static-asset>
  <app-container>
    <div class="prose text-center">
      <p>
        Dank langjährigen Partnerschaften mit erstklassigen Züchtern sind wir
        Ihre Quelle für aktuelle Schnäppchen und schöne Pflanzen direkt aus den
        Anbaukulturen.
      </p>
    </div>
  </app-container>

  <app-section-items>
    <app-section-header>Shop</app-section-header>
    <app-section-item
      ><a class="link" [routerLink]="['./ueber-uns']"
        >Über uns</a
      ></app-section-item
    >
    <app-section-item
      ><a class="link" [routerLink]="['./versand']"
        >Versandinformationen</a
      ></app-section-item
    >
    <app-section-item
      ><a class="link" [routerLink]="['./zahlungsmethoden']"
        >Zahlungsmethoden</a
      ></app-section-item
    >
    <app-section-item
      ><a class="link" [routerLink]="['./schadensmeldung']"
        >Schadensmeldung</a
      ></app-section-item
    >
  </app-section-items>

  <app-section-items>
    <app-section-header>Legal</app-section-header>
    <app-section-item
      ><a class="link" [routerLink]="['./agb']">AGB</a></app-section-item
    >
    <app-section-item
      ><a class="link" [routerLink]="['./datenschutz']"
        >Datenschutz</a
      ></app-section-item
    >
    <app-section-item
      ><a class="link" [routerLink]="['./wiederrufsrecht']"
        >Wiederrufsrecht</a
      ></app-section-item
    >
    <app-section-item
      ><a class="link" [routerLink]="['./impressum']"
        >Impressum</a
      ></app-section-item
    >
  </app-section-items>
</div>
