import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { ButtonPrimaryComponent } from '../../components/button-primary/button-primary.component';
import { ContainerComponent } from '../../components/container/container.component';
import { StaticAssetComponent } from '../../components/static-asset/static-asset.component';
import { EventDirective } from '../../event.directive';
import { Router } from '@angular/router';
import { Schemas } from '../../../api-types/storeApiTypes';

@Component({
    selector: 'app-cart-empty',
    imports: [
        ButtonPrimaryComponent,
        ContainerComponent,
        StaticAssetComponent,
        EventDirective,
    ],
    templateUrl: './cart-empty.component.html',
    styleUrl: './cart-empty.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartEmptyComponent {
  readonly router = inject(Router);
  cartState = input.required<Schemas['Cart']>();

  goToShop = () => {
    return this.router.navigate(['/zimmerpflanzen']);
  };
}
