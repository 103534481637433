import {inject, Injectable} from '@angular/core';
import {updateCanonicalLink} from './seo';
import {Meta, Title} from '@angular/platform-browser';
import {Schemas} from '../api-types/storeApiTypes';
import {environment} from '../environments/environment';
import {DOCUMENT} from '@angular/common';
import {MetaInfo} from "./metaInfo";

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  readonly meta = inject(Meta);
  readonly title = inject(Title);
  readonly shopUrl = environment.storefrontUrl;
  readonly dom = inject(DOCUMENT);

  setMetaTags(meta: MetaInfo, seo: Schemas['SeoUrl'] | undefined) {
    this.title.setTitle(meta.metaTitle ?? meta.name ?? '');
    this.meta.updateTag(
      {
        name: 'description',
        content: meta.metaDescription ?? meta.description ?? '',
      },
      'name="description"',
    );
    this.meta.updateTag(
      {
        name: 'keywords',
        content: meta.keywords ?? '',
      },
      'name="keywords"',
    );

    if (seo) {
      updateCanonicalLink(this.dom, `${this.shopUrl}/${seo.seoPathInfo}`);
    }
  }
}
