<app-collapsable [stickyOption]="stickyOption()">
  <app-collapsable-header>
    <ng-container
      *ngTemplateOutlet="hierarchy().itemTemplate ?? defaultItemTemplate(); context: { item: hierarchy().item }">
    </ng-container>
  </app-collapsable-header>
  <app-collapsable-body>
    @for (child of hierarchy().children; track child.item.id) {
      @if (child.children.length > 0) {
        <app-collapsable-hierarchy [stickyOption]="childStickyOption()" [hierarchy]="child" [defaultItemTemplate]="defaultItemTemplate()"></app-collapsable-hierarchy>
      } @else {
        <ng-container
          *ngTemplateOutlet="child.itemTemplate ?? defaultItemTemplate(); context: { item: child.item }">
        </ng-container>
      }
    }
  </app-collapsable-body>
</app-collapsable>
