import {ChangeDetectionStrategy, Component, computed, input, TemplateRef} from '@angular/core';
import {CollapsableHeaderComponent} from "../collapsable-header/collapsable-header.component";
import {CollapsableBodyComponent} from "../collapsable-body/collapsable-body.component";
import {CollapsableComponent} from "../collapsable/collapsable.component";
import {NgTemplateOutlet} from "@angular/common";
import {Category} from "../../category.service";

export interface ShowMoreOption {
  selected: boolean;
  count: number;
}

export interface Hierarchy<T extends Category> {
  item: T;
  itemTemplate: TemplateRef<{ item: T }> | undefined;
  children: Hierarchy<T>[];
  level: number;
  showMoreOption: ShowMoreOption | null;
}

export interface StickyOption {
  isSticky: boolean;
  zIndex: number;
}

@Component({
  selector: 'app-collapsable-hierarchy',
  imports: [
    CollapsableComponent,
    CollapsableHeaderComponent,
    CollapsableBodyComponent,
    NgTemplateOutlet,
  ],
  templateUrl: './collapsable-hierarchy.component.html',
  styleUrl: './collapsable-hierarchy.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsableHierarchyComponent<T extends Category> {
  hierarchy = input.required<Hierarchy<T>>();
  defaultItemTemplate = input.required<TemplateRef<{ item: T }>>();
  stickyOption = input<StickyOption>({
    isSticky: false,
    zIndex: 0
  });

  childStickyOption = computed(() => ({
    isSticky: this.stickyOption().isSticky,
    zIndex: this.stickyOption().zIndex + (this.hierarchy().level * 50) + 1,
  }));
}
