import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { CookieStateService } from './cookie-state.service';
import { ContainerComponent } from '../container/container.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { StaticAssetComponent } from '../static-asset/static-asset.component';
import { CollapsableComponent } from '../collapsable/collapsable.component';
import { CollapsableHeaderComponent } from '../collapsable-header/collapsable-header.component';
import { CollapsableBodyComponent } from '../collapsable-body/collapsable-body.component';
import { EventDirective } from '../../event.directive';
import { DialogComponent } from '../dialog/dialog.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-cookie-banner',
  imports: [
    ContainerComponent,
    ReactiveFormsModule,
    CollapsableComponent,
    CollapsableHeaderComponent,
    CollapsableBodyComponent,
    StaticAssetComponent,
    EventDirective,
    DialogComponent,
    NgClass,
  ],
  templateUrl: './cookie-banner.component.html',
  styleUrl: './cookie-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieBannerComponent {
  cookieState = inject(CookieStateService);
  fb = inject(FormBuilder);

  more = signal(false);

  form = this.fb.group({
    functional: this.fb.control(true, {
      nonNullable: true,
      validators: [Validators.requiredTrue],
    }),
    analytics: this.fb.control(true, { nonNullable: true }),
    marketing: this.fb.control(true, { nonNullable: true }),
  });

  constructor() {
    this.form.controls.functional.disable();
  }

  toggleInfo() {
    this.more.update((s) => !s);
  }

  accept(dialog: DialogComponent) {
    this.cookieState.accept(this.form.getRawValue());
    dialog.close();
  }

  acceptAll(dialog: DialogComponent) {
    this.cookieState.accept({
      functional: true,
      analytics: true,
      marketing: true,
    });
    dialog.close();

    (window as any).gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  }
}
