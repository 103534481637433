import {
  computed,
  inject,
  Injectable,
  makeStateKey,
  signal,
} from '@angular/core';
import { StoreApiService } from './store-api.service';
import { ListState } from './listStore';
import { Schemas } from '../api-types/storeApiTypes';
import {Hierarchy, ShowMoreOption} from "./components/collapsable-hierarchy/collapsable-hierarchy.component";
import {MetaInfo} from "./metaInfo";

export interface Category {
  id: string;
  parentId: string | null;
  tree: string[];
  name: string;
  position: number;
  description: string;
  navDescription: string | null;
  breadcrumbs: string[];
  level: number;
  url: string;
  meta: MetaInfo;
  media: string | undefined;
  seoUrl: Schemas['SeoUrl'] | undefined;
  visible: boolean | undefined;
  seoText: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  storeApi = inject(StoreApiService);

  store = this.storeApi.createApiStore(
    makeStateKey<ListState<Category>>('categories'),
    () =>
      this.storeApi.apiClient
        .invoke('readCategoryList post /category', {
          body: {
            associations: {
              seoUrls: {},
              media: {},
            },
          },
        })
        .then((r) => ({
          elements: (r.data.elements as Schemas['Category'][])
            .filter((e) => e.active)
            .map<Category>((c) => ({
              id: c.id,
              description: (c.description ?? '').replace(/<[^>]*>/g, ''),
              parentId: c.parentId ?? null,
              name: c.translated.name ?? c.name,
              breadcrumbs: c.translated.breadcrumb,
              level: c.level ?? 0,
              url: c.seoUrls ? '/' + c.seoUrls[0]?.seoPathInfo : '',
              seoUrl: c.seoUrls ? c.seoUrls[0] : undefined,
              meta: c.translated,
              position: this.getPosition(
                c,
                r.data.elements as Schemas['Category'][],
              ),
              media: c.media?.url,
              navDescription: c.customFields ? c.customFields['custom_navigation_description'] : '',
              visible: c.visible,
              tree: this.getTree(c, r.data.elements as Schemas['Category'][]),
              seoText: c.customFields ? c.customFields['custom_category_seo_text_body'] : null,
            })),
          total: r.data.total ?? 0,
        })),
  );

  private getPosition(
    category: Schemas['Category'],
    categories: Schemas['Category'][],
  ): number {
    if (!category.afterCategoryId) {
      return 0;
    }
    const afterCategory = categories.find(
      (c) => c.id === category.afterCategoryId,
    );
    if (!afterCategory) {
      return 0;
    }
    return this.getPosition(afterCategory, categories) + 1;
  }

  current = signal<Category | null>(null);

  categories = computed<Category[]>(() => {
    const state = this.store();
    if (state.loading) {
      return [];
    }
    return state.elements;
  });

  private getTree(category: Schemas['Category'], categories: Schemas['Category'][]): string[] {
    if (!category) {
      return [];
    }

    if (!category.parentId || !categories) {
      return [category.id];
    }

    const parent = categories.find((c) => c.id === category.parentId);
    if (!parent || parent.level === 1) {
      return [category.id];
    }

    return this.getTree(parent, categories).concat([category.id]);
  }

  mainCategories = computed(() => {
    return this.categories()
      .filter((c) => c.level === 2 && c.visible)
      .sort((f, s) => f.position - s.position);
  });

  subCategories = computed(() => {
    return this.categories().filter((c) => c.parentId === this.current()?.id);
  });

  hierarchicalCategories = computed(() => {
    return this.mainCategories().map<Hierarchy<Category>>((c) => this.mapCategoriesToHierarchy(c, 0, null)) });

  showMoreHierarchicalCategories = computed(() => {
    const showMoreCategory = this.selectedShowMoreHierarchicalCategory();
    const hierarchy = this.mainCategories().map<Hierarchy<Category>>((c) => this.mapCategoriesToHierarchy(c, 0, {
      selected: false,
      count: 3,
    }));

    if (showMoreCategory) {
      const item = hierarchy.find((h) => h.item.id === showMoreCategory.item.id);

      if (item) {
        item.showMoreOption = {
          selected: true,
          count: item.children.length,
        }
      }
    }

    return hierarchy;
  });


  selectedShowMoreHierarchicalCategory = signal<Hierarchy<Category> | null>(null);
  updateShowMoreHierarchicalCategory(hierarchy: Hierarchy<Category> | null) {
    this.selectedShowMoreHierarchicalCategory.set(hierarchy);
  }

  private mapCategoriesToHierarchy(category: Category, level: number, showMoreOption: ShowMoreOption | null): Hierarchy<Category> {
    const children = this.categories()
      .filter((c) => c.parentId === category.id && c.visible);

    if (children.length === 0) {
      return ({
        item: category,
        itemTemplate: undefined,
        children: [],
        level,
        showMoreOption,
      });
    }

    return ({
      item: category,
      itemTemplate: undefined,
      children: children.map((c) => this.mapCategoriesToHierarchy(c, level + 1, showMoreOption)).sort((f, s) => f.item.position - s.item.position),
      level,
      showMoreOption,
    })
  }
}
