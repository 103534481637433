import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CategoryService } from '../../category.service';

@Component({
    selector: 'app-product-category-header',
    imports: [],
    templateUrl: './product-category-header.component.html',
    styleUrl: './product-category-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCategoryHeaderComponent {
  category = inject(CategoryService);
}
